.perspectiveBar {
  width: 100%;
  height: 40%;
  position: fixed;
  bottom: 0;
  background-color: hsl(195deg, 20%, 86%);
}

@media screen and (max-width: 550px) {
  .perspectiveBar {
    display: none;
  }
}