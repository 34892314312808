html,
body,
#root {
  height: 100%;
}

body {
  background-color: hsl(180deg, 25%, 92%);
  margin: 0;
}

*,
*:before,
*:after {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

/*
  A utility class to totally remove something from the screen,
  while still keeping it available for screen-readers.
*/
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
