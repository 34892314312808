.footer {
  position: relative;
  padding: 64px;
  background: hsl(0deg, 0%, 5%);
  color: white;
  text-align: center;
}

.footer a {
  color: currentColor;
}

@media screen and (max-width: 550px) {
  .footer {
    margin-top: 30%;
  }
}