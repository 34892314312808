.characterEditor {
  display: flex;
  padding-top: 128px;
  padding-bottom: 64px;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.maxWidthWrapper {
  width: 65%;
}

.header {
  padding-bottom: 64px;
}

.title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
}

.description {
  font-size: 1.25rem;
  margin: 0;
}

.characterWrapper {
  position: sticky;
  top: 128px;
  width: 100%;
  min-height: 500px;
  height: min-content;
}

.controlColumn {
  width: 80%;
}

@media screen and (max-width: 550px) {
  .maxWidthWrapper {
    width: 100%;
  }

  .controlColumn {
    position: relative;
    width: calc(100% + 64px);
    display: flex;
    margin: 100% -32px 0 -32px;
    padding-left: 32px;
    gap: 16px;
    overflow-y: scroll;

    z-index: 1;
    /* Hide Firefox scrollbar */
    scrollbar-width: none;
  }

  .controlColumn::-webkit-scrollbar {
    display: none;
  }

  .characterWrapper {
    position: absolute;
    top: calc(40% + 128px);
    left: 10vw;
    width: calc(100% - 10vw);
  }
}
